// Global packages and components
import { useState, useEffect } from 'react'
import { useFetcher, useRouteLoaderData } from '@remix-run/react'
import {
  makeRequest,
  createProductQueryBody,
  formatActivePriceFilter,
} from '~/utils'
import { action as tokenAction } from '~/routes/api.token'

// Types
import type {
  BrendaSearchFilter,
  BrendaSearchProduct,
  BrendaSearchProductsResponse,
  ProductListingPriceValues,
  RootDataProps,
} from '~/types'

interface Props {
  setPriceValues?: (e: ProductListingPriceValues) => void
}

interface QueryProps {
  searchTerm?: string
  limit?: string
  page?: string
  onSale?: string
  orderBy?: string
  categorySlug?: string
  categoryFilters?: string[]
  productType?: string
  productCodes?: string[]
  attributes?: string[]
  priceRange?: string
  returnFilters?: boolean
}

// Main export
const useProducts = ({ setPriceValues }: Props = {}) => {
  // Root env
  const { env } = useRouteLoaderData('root') as RootDataProps

  // Hooks
  const fetcher = useFetcher<typeof tokenAction>()

  // Query states
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  // Query results
  const [total, setTotal] = useState<number>(0)
  const [products, setProducts] = useState<BrendaSearchProduct[]>([])
  const [filters, setFilters] = useState<BrendaSearchFilter[]>([])
  const [priceLimit, setPriceLimit] = useState<BrendaSearchFilter | null>(null)

  // Failed request
  const failedRequest = (message: string) => {
    setProducts([])
    setFilters([])
    setError(message)
  }

  // Get token from the API endpoint
  const getProducts = async ({
    searchTerm,
    limit,
    page,
    onSale,
    orderBy,
    categorySlug,
    categoryFilters,
    productType,
    productCodes,
    attributes,
    priceRange,
    returnFilters,
  }: QueryProps = {}) => {
    fetcher.submit(
      {
        transitData: JSON.stringify({
          searchTerm: searchTerm ?? '*',
          limit: limit ?? '9',
          page: page ?? '1',
          onSale: onSale == 'true' ? 'true' : '',
          orderBy: orderBy ?? 'RELEVANCE',
          categorySlug: categorySlug,
          categoryFilters: categoryFilters,
          productType: productType,
          productCodes: productCodes ?? [],
          attributes: attributes && attributes?.length > 0 ? attributes : [],
          priceRange: priceRange,
          returnFilters: returnFilters,
        }),
      },
      { method: 'post', encType: 'application/json', action: '/api/token' }
    )
  }

  // Make API fetch to HSS Brenda
  const fetchFromBrenda = async ({
    token,
    transitData,
  }: {
    token: string
    transitData?: QueryProps
  }): Promise<BrendaSearchProductsResponse> => {
    const queryBody = createProductQueryBody({ ...transitData })

    // @NOTE: Testing
    console.log('query', queryBody)

    if (!env?.BRENDA_API_URL) {
      throw new Error('Missing credentials')
    }

    return await makeRequest({
      url: `${env.BRENDA_API_URL}/catalogue/product-search`,
      timeoutSeconds: 10,
      options: {
        method: 'POST',
        body: JSON.stringify(queryBody),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    })
  }

  // Once token is generated/fetched, get product prices
  useEffect(() => {
    setError(null)

    if (fetcher?.state == 'loading') {
      setLoading(true)
    }

    if (fetcher?.data) {
      if (fetcher?.data?.token) {
        fetchFromBrenda({
          token: fetcher.data.token,
          transitData: fetcher?.data?.transitData
            ? JSON.parse(fetcher.data.transitData)
            : null,
        })
          .then(data => {
            // @NOTE: Testing
            console.log('dataFromBrenda', data)

            // Set total products count
            if (data?.body?.entities[0]?.meta?.totalResultsFound) {
              setTotal(data.body.entities[0].meta.totalResultsFound)
            } else {
              setTotal(0)
            }

            // Set products data
            // @NOTE: We are changing the prices to include VAT
            if (data?.body?.entities[0]?.records) {
              setProducts(
                data.body.entities[0].records.map(e => {
                  return {
                    ...e,
                    ...(e?.guidePrice?.amount &&
                      e?.guidePrice?.currency && {
                        guidePrice: {
                          amount: e.guidePrice.amount,
                          currency: e.guidePrice.currency,
                        },
                      }),
                    ...(e?.salePrice?.amount &&
                      e?.salePrice?.currency && {
                        salePrice: {
                          amount: e.salePrice.amount,
                          currency: e.salePrice.currency,
                        },
                      }),
                  }
                })
              )
            } else {
              setProducts([])
            }

            // Set dynamic filters data
            if (data?.body?.entities[0]?.filters) {
              setFilters(
                data.body.entities[0].filters.filter(
                  e => e.label !== 'klevu_price'
                )
              )

              const onlyPrice = data.body.entities[0].filters.find(
                e => e.label == 'klevu_price'
              )

              if (onlyPrice) {
                setPriceLimit(onlyPrice)
              }
            } else {
              setFilters([])
            }
          })
          .catch(error => {
            failedRequest('There was a problem fetching products')
            console.error(error)
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        failedRequest('Could not authenticate request')
        setLoading(false)
      }
    }
  }, [fetcher])

  // Set the initial price values when limits are received from klevu
  useEffect(() => {
    if (priceLimit && setPriceValues) {
      setPriceValues(formatActivePriceFilter({ ...priceLimit }))
    }
  }, [priceLimit])

  return {
    loading,
    error,
    products,
    filters,
    priceLimit,
    total,
    getProducts,
    setLoading,
  }
}

export default useProducts
