// Global packages and components
import { clsx } from 'clsx'
import { LoaderIcon } from '~/components'

// Types
import type { ReactNode } from 'react'

interface Props {
  children?: ReactNode
}

// Main export
const InlineLoader = ({ children }: Props) => (
  <div className="mb-10 flex w-full flex-col items-center rounded-2xl bg-gray-100 px-8 py-14 text-center">
    <div
      className={clsx({
        'mb-5': children,
      })}
    >
      <LoaderIcon />
    </div>
    {children}
  </div>
)

export default InlineLoader
