// Global packages and components
import { Link } from '@remix-run/react'
import { ProductImage, Price, Button, Trustpilot } from '~/components'
import { getProductPageLink, hasVariations } from '~/utils'

// Types
import type { BrendaSearchProduct } from '~/types'

const ProductCard = ({
  name,
  parentProduct,
  guidePrice,
  salePrice,
  option,
  images,
  promotionName,
  stockCode,
}: BrendaSearchProduct) => {
  // @TODO: To be removed, just because of missing HSS data
  const hasLink =
    parentProduct.hyraxProductType == 'bulk' ||
    parentProduct.hyraxProductType == 'consumable'
      ? true
      : false

  const link = getProductPageLink({
    productType: parentProduct.hyraxProductType,
    stockCode: parentProduct.stockCode,
    slug: parentProduct.slug,
    variant: option?.value,
  })

  return (
    <div className="flex h-full flex-col rounded-2xl bg-neutral-100 p-3 md:p-4">
      <Link
        to={hasLink ? link : '/'}
        className="mb-4 block"
      >
        <ProductImage
          src={
            images && images?.length
              ? images[0]?.imageUrl
              : 'https://placehold.co/600x400'
          }
          alt="placeholder"
          hasVariations={hasVariations(parentProduct)}
          tags={promotionName ? [{ label: promotionName, style: 'red' }] : []}
        />
      </Link>
      <h3 className="mb-0 text-base font-semibold md:text-lg">{name}</h3>
      <div className="mb-2 pt-2">
        <Trustpilot
          templateId="product-mini"
          product={{ sku: stockCode, name: name }}
        />
      </div>
      <div className="mt-auto">
        {parentProduct?.hyraxProductType == 'bulk' && (
          <p className="mb-1 text-xs">From:</p>
        )}
        <Price
          highlight
          price={guidePrice}
          salePrice={salePrice}
        />
        <div className="pt-5">
          <Button
            disabled={!hasLink}
            to={link}
            title={
              !hasLink
                ? 'Broken data'
                : parentProduct?.hyraxProductType == 'bulk'
                  ? 'Hire now'
                  : 'Buy now'
            }
            style="tealGreen"
            fullWidth
          />
        </div>
      </div>
    </div>
  )
}

export default ProductCard
