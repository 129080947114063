import _ from 'lodash'
import slugify from 'slugify'
import type {
  CreateQueryBodyProps,
  QueryBodyProps,
  QueryBodyAttribute,
  ShowProductsCountProps,
  ProductDetailsVariantProps,
  ProductListingPriceValues,
  BrendaSearchFilter,
  BrendaSearchProduct,
} from '~/types'

/**
 * Pagination information for listing pages
 *
 * @returns {string}
 */
export const shownProductsCount = ({
  limit,
  page,
  total,
}: ShowProductsCountProps): string => {
  const limitNumber = Number(limit)
  const pageNumber = Number(page)
  const toValue = total < limitNumber ? total : limitNumber * pageNumber

  return `Showing ${pageNumber == 1 ? 1 : limitNumber * pageNumber - limitNumber} - ${toValue} out of ${total} entries`
}

// @ref: https://docs.klevu.com/klevucore-reference/enumeration-klevusearchsorting
const getKlevuSortNames = (orderBy: string): string => {
  if (orderBy == 'price-low') {
    return 'PRICE_ASC'
  } else if (orderBy == 'price-high') {
    return 'PRICE_DESC'
  } else {
    return 'RELEVANCE'
  }
}

/**
 * Used for transforming query string attributes into a Brenda/Klevu format to be passed to the API
 *
 * @returns {object}
 */
export const formatAttributeFilters = (
  attributes: string[]
): QueryBodyAttribute[] => {
  // Splits attribute filters by their name and value and formats each as an array with 1 value
  const objectsArr = attributes
    .map(e => _.zipObject(['key', 'values'], decodeURI(e).split('|')))
    .map(e => {
      return {
        key: e.key,
        values: [e.values],
        settings: {
          singleSelect: e.key == 'stockCode' ? false : true,
        },
      }
    })

  // Merges the array item values if they have the same name
  const objectsMerged = _.uniqWith(objectsArr, (pre, cur) => {
    if (pre.key === cur.key) {
      pre.values = cur.values = pre.values.concat(cur.values)

      return true
    }

    return false
  })

  return objectsMerged
}

/**
 * Creates the body object to be sent to the Brenda/Klevu API
 *
 * @returns {QueryBodyProps}
 */
export const createProductQueryBody = ({
  limit,
  page,
  onSale,
  searchTerm,
  orderBy,
  categorySlug,
  productType,
  productCodes,
  attributes,
  priceRange,
  returnFilters = true,
  categoryFilters = [],
}: CreateQueryBodyProps): QueryBodyProps => {
  const limitNumber = limit ? Number(limit) : 9
  const pageNumber = page ? Number(page) : 1
  const queryString = searchTerm ? searchTerm : '*'
  const sortOrder = orderBy ? getKlevuSortNames(orderBy) : 'RELEVANCE'

  // Filter by custom attributes
  const attributeFilters =
    attributes && attributes.length > 0
      ? formatAttributeFilters(attributes)
      : []

  // Fixed price filter
  const priceFilter = priceRange
    ? [
        {
          key: 'klevu_price',
          values: [priceRange],
          settings: {
            singleSelect: true,
          },
        },
      ]
    : []

  // Fixed category filter (none customer controlled)
  const categoryFilter = categorySlug
    ? [
        {
          key: 'categories',
          values: [categorySlug],
          settings: {
            singleSelect: true,
          },
        },
      ]
    : []

  // Fixed vertical filter (none customer controlled)
  const typeFilter = productType
    ? [
        {
          key: 'hyraxProductType',
          values: [productType],
          settings: {
            singleSelect: true,
          },
        },
      ]
    : []

  // Fixed stock code filter (none customer controlled)
  const codesFilter =
    productCodes && productCodes?.length > 0
      ? [
          {
            key: 'stockCode',
            values: productCodes,
            settings: {
              singleSelect: false,
            },
          },
        ]
      : []

  const saleFilter = onSale
    ? [
        {
          key: 'onSale',
          values: [onSale],
          settings: {
            singleSelect: true,
          },
        },
      ]
    : []

  const defaultFilters = [
    'brand',
    'height',
    'width',
    'length',
    'depth',
    'weight',
  ]

  const returnFilterSettings = returnFilters
    ? {
        enabled: true,
        include: [...defaultFilters, ...categoryFilters],
        rangeFilterSettings: [
          {
            key: 'klevu_price',
            minMax: true,
            rangeInterval: 0,
          },
        ],
      }
    : {}

  const queryBody = {
    query: queryString,
    limit: limitNumber,
    offset: pageNumber <= 1 ? 0 : pageNumber * limitNumber - limitNumber,
    sort: sortOrder,
    filtersToReturn: returnFilterSettings,
    applyFilters: {
      filters: [
        ...categoryFilter,
        ...priceFilter,
        ...typeFilter,
        ...attributeFilters,
        ...codesFilter,
        ...saleFilter,
      ],
    },
  }

  return queryBody
}

/**
 * Overwrite a certain set of data if the child exists, otherwise use parent
 *
 * @returns {T}
 */
export const productDataToUse = <T>(parent: T, child: T): T => {
  return child ? child : parent
}

/**
 * Get the active images for a product single page
 *
 * @returns {array}
 */
export const getVariantImages = (
  variants: ProductDetailsVariantProps[],
  selected: string
): { imageUrl: string }[] => {
  const variant = variants.find(e => e.stockCode == selected)

  if (variant?.images) {
    return variant.images
  } else {
    return []
  }
}

/**
 * Formats the attributes for the URL on listing pages
 *
 * @returns {string}
 */
export const formatQueryFilter = (value: string) => {
  return slugify(_.kebabCase(value))
}

/**
 * Checks to see if the price filters are active, or just matching min/max default
 *
 * @returns {boolean}
 */
export const hasActivePriceFilter = ({
  start,
  end,
  min,
  max,
}: ProductListingPriceValues): boolean => {
  if (min == undefined || max == undefined) {
    return false
  }

  if (start == min && end == max) {
    return false
  }

  return true
}

/**
 * Sets the object for our price filter values when received from query
 *
 * @returns {BrendaSearchFilter}
 */
export const formatActivePriceFilter = ({
  start,
  end,
  min,
  max,
}: BrendaSearchFilter): ProductListingPriceValues => {
  return {
    start: start ? start : min ? min : 0,
    end: end ? end : max ? max : 0,
    min: min ?? undefined,
    max: max ?? undefined,
  }
}

export const hasVariations = (
  parentProduct: BrendaSearchProduct['parentProduct']
) => {
  return parentProduct?.options &&
    parentProduct?.options?.values?.length &&
    parentProduct?.options?.name !== 'Default'
    ? true
    : false
}

// Options for order by
export const orderByOptions = [
  {
    value: 'relevance',
    label: 'Relevance',
  },
  {
    value: 'price-high',
    label: 'Price (High - Low)',
  },
  {
    value: 'price-low',
    label: 'Price (Low - High)',
  },
]
